<template>
  <div class="money-detail">
    <div class="m-header">
      <div class="m-icon" @click="toHome">
        <span class="iconfont icon-left"></span>
      </div>
      <div class="m-title">提现</div>
    </div>
    <div class="type-list">
      <div v-for="(item, index) in redbagTypeList" :key="index" class="type-item" @click="toWithdrawal(item)">
        <div class="left">
          <div :class="['redbag-icon', item.activity_rule_type]"></div>
          <div class="redbag-info">
            <div class="amount">{{ item.balance }} 元</div>
            <div v-if="item.activity_rule_type === 'repay'" class="tips">充返红包余额</div>
            <div v-else-if="item.activity_rule_type === 'pay'" class="tips">充值红包余额</div>
            <div v-else-if="item.activity_rule_type === 'login'" class="tips">登录红包余额</div>
            <div v-else-if="item.activity_rule_type === 'level'" class="tips">等级红包余额</div>
          </div>
        </div>
        <div class="right">
          <div class="text">提现</div>
          <div class="icon"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getBalanceList } from '@/api/redList';
export default {
  name: 'WithdrawalType',
  data() {
    return {
      redbagTypeList: [],
    };
  },
  activated() {
    this.getList();
  },
  methods: {
    getList() {
      this.redbagTypeList = [];
      getBalanceList({ app_id: window.sessionStorage.getItem('appid'), token: window.sessionStorage.getItem('ato') }).then((res) => {
        console.log('提现红包类型列表', res);
        // for (const item in res.data.data) {
        //   this.redbagTypeList.push({ type: item, num: res.data.data[item] });
        // }
        this.redbagTypeList = res.data.data;
        console.log(this.redbagTypeList);
      });
    },
    toHome() {
      this.$router.push('/home');
    },
    toWithdrawal(item) {
      this.$router.push({
        path: '/withdrawal',
        query: {
          activity_rule_type: item.activity_rule_type,
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.money-detail {
  background-color: #f7f7f7;
  min-height: 100vh;
  .m-header {
    position: relative;
    background-color: #ffffff;

    height: 1rem;
    display: flex;
    text-align: center;
    align-items: center;
    .m-icon {
      position: absolute;
      // margin-left: 0.3rem;
      width: 1rem;
      height: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      .icon-left {
        font-size: 0.34rem;
        color: #333;
      }
    }
    .m-title {
      flex: 1;
      font-size: 0.32rem;
    }
  }

  .type-list {
    padding: 0 0.15rem;
    .type-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 1.2rem;
      background: #ffffff;
      box-shadow: 0.01rem 0.04rem 0.09rem 0px rgba(33, 38, 116, 0.02);
      border-radius: 0.1rem;
      margin-top: 0.2rem;
      padding: 0.25rem 0.3rem;
      box-sizing: border-box;
      .left {
        display: flex;
        align-items: center;
        .redbag-icon {
          width: 0.78rem;
          height: 0.78rem;
          background-image: url('../../../assets/imgs/chongfan.png');
          background-size: 100% 100%;
          background-repeat: no-repeat;
          background-position: center center;
          &.repay {
            background-image: url('../../../assets/imgs/chongfan.png');
          }
          &.pay {
            background-image: url('../../../assets/imgs/chongzhi.png');
          }
          &.login {
            background-image: url('../../../assets/imgs/denglu.png');
          }
          &.level {
            background-image: url('../../../assets/imgs/dengji.png');
          }
        }
        .redbag-info {
          font-family: Source Han Sans SC;
          margin-left: 0.22rem;
          .amount {
            font-size: 0.32rem;
            font-weight: 500;
            color: #151524;
          }
          .tips {
            font-size: 0.24rem;
            font-weight: 400;
            color: #151524;
          }
        }
      }
      .right {
        display: flex;
        align-items: center;
        .text {
          font-size: 0.28rem;
          font-family: Source Han Sans SC;
          font-weight: 500;
          color: #151524;
          margin-right: 0.2rem;
        }
        .icon {
          width: 0.18rem;
          height: 0.28rem;
          background-image: url('../../../assets/imgs/left.png');
          background-size: 100% 100%;
          background-repeat: no-repeat;
          background-position: center center;
        }
      }
    }
  }
}
</style>
