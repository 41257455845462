import _axios from './request';
import v2request from './v2request';
import v3request from './v3request';
// import Qs from "qs"
import { buildParams } from '@/utils/sign';
//活动红包列表
export function getRedList(data) {
  data = buildParams(data);
  return v3request({
    method: 'get',
    url: '/api/v1/AppRedbagActivity/list',
    //   data: Qs.stringify(data)
    params: data,
  });
}
// export function getRedList(data) {
//     data = buildParams(data)
//     return _axios({
//         method: "get",
//         url: "/red_package/",
//         //   data: Qs.stringify(data)
//         params: data
//     })
// }
//活动指标统计
export function redConfig(data) {
  data = buildParams(data);
  return v3request({
    method: 'get',
    url: '/api/v1/AppRedbagActivity/activityStatistic',
    //   data: Qs.stringify(data)
    params: data,
  });
}
//领取红包逻辑
export function getRedPackage(data) {
  data = buildParams(data);
  return v3request({
    method: 'post',
    url: '/api/v1/AppRedbagActivity/receive',
    //   data: Qs.stringify(data)
    data: data,
  });
}

// export function getRedPackage(data) {
//     data = buildParams(data)
//     return _axios({
//         method: "get",
//         url: "/red_package/receive/",
//         //   data: Qs.stringify(data)
//         params: data
//     })
// }

//红包明细
export function getRedPackageList(data) {
  data = buildParams(data);
  return v3request({
    method: 'get',
    url: '/api/v1/AppRedbagActivity/redbagRecord',
    //   data: Qs.stringify(data)
    params: data,
  });
}
//提现
export function withdrawRedPackage(data) {
  data = buildParams(data);
  return v3request({
    method: 'post',
    url: '/api/v1/AppRedbagActivity/withdraw',
    data: data,
    // params: data
  });
}

// export function withdrawRedPackage(data) {
//     data = buildParams(data)
//     return _axios({
//         method: "post",
//         url: "/red_package/withdraw/",
//         data: Qs.stringify(data)
//         // params: data
//     })
// }

export function getWxMpConfig(data) {
  data = buildParams(data);
  return v2request({
    method: 'get',
    url: '/v2/userBind/wxMpConfig',
    params: data,
  });
}
//提现类型列表
export function getBalanceList(data) {
  data = buildParams(data);
  return v3request({
    method: 'get',
    url: '/api/v1/AppRedbagActivity/withdrawList',
    params: data,
  });
}
//提现配置的列表
export function getWithdrawConfig(data) {
  data = buildParams(data);
  return v3request({
    method: 'get',
    url: '/api/v1/AppRedbagActivity/withdrawConfig',
    params: data,
  });
}

export function getUserInfo(data) {
  // console.log(Qs.stringify(data))
  data = buildParams(data);
  return v3request({
    method: 'get',
    url: '/api/v1/AppRedbagActivity/user_info',
    //   data: Qs.stringify(data)
    params: data,
  });
}
